import {
  Button, Divider, Form, Input, InputNumber, Modal, Select,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import products from './products';
import { allStates } from './states';

const { Option } = Select;

function SettingsModal({ visible, onCancel, onConfirm }) {
  const [form] = useForm();
  const [filterMode, setFilterMode] = useState(null);

  const filters = ['Product', 'State'];

  const settingsInputs = [
    {
      name: 'Quantity',
      label: 'Number of Calls',
      type: 'numeric',
      required: true,
    },
    {
      name: 'Filter',
      label: 'Filter by',
      type: 'select',
      children: filters.map((filter) => (
        <Option value={filter} title={filter.name} key={filter}>
          {filter}
        </Option>
      )),
      onChange: (value) => setFilterMode(value),
    },
    ...[
      filterMode === 'Product' && {
        name: 'Products',
        label: 'Filter by Product',
        type: 'select',
        children: products.map((product) => (
          <Option value={product.id} title={product.name} key={product.id}>
            {product.name}
          </Option>
        )),
        mode: 'multiple',
      },
    ],
    ...[
      filterMode === 'State' && {
        name: 'States',
        label: 'Filter by State',
        type: 'select',
        children: allStates.map((state) => (
          <Option value={state.id} title={state.name} key={state.id}>
            {state.name}
          </Option>
        )),
        mode: 'multiple',
      },
    ],
  ];

  const getFieldInput = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            placeholder="No filter applied"
            filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
            mode={field.mode}
            allowClear
            onChange={field.onChange}
          >
            {field.children}
          </Select>
        );
      case 'numeric':
        return <InputNumber min={1} max={50} />;
      default:
        return <Input />;
    }
  };

  const onSave = async () => {
    try {
      const settings = await form.validateFields();
      console.log({ settings });
      onConfirm(settings);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      width={600}
      visible={visible}
      closable
      onCancel={() => onCancel()}
      footer={[
        <Button
          form="addForm"
          key="cancel"
          htmlType="button"
          onClick={onCancel}
        >
          Cancel
        </Button>,
        <Button form="addForm" key="submit" type="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
    >
      <div>
        <p>APP SETTINGS</p>
        <Divider type="horizontal" />
      </div>
      <Form form={form} requiredMark={false} initialValues={{ Quantity: 1 }}>
        <div style={{ marginTop: 20 }}>
          {settingsInputs.map(
            (field) => field && (
            <Form.Item
              colon={false}
              label={field.label || field.name}
              name={field.name}
              key={field.name}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              labelAlign="left"
              rules={[
                {
                  required: field.required,
                  message: `${field.label} is required.`,
                },
              ]}
              style={{ marginLeft: 30, marginRight: 30 }}
            >
              {getFieldInput(field)}
            </Form.Item>
            ),
          )}
        </div>
      </Form>
    </Modal>
  );
}

export default SettingsModal;

SettingsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
