/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import {
  Button, Col, message, Modal, Row, Table,
} from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import SettingsModal from './SettingsModal';

const { REACT_APP_API_URL } = process.env;

function App() {
  const [logs, setLogs] = useState([]);
  const [fetchingLogs, setFetchingLogs] = useState(false);
  const [calling, setCalling] = useState(false);
  const [stopping, setStopping] = useState(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [settings, setSettings] = useState({
    Filter: undefined,
    Quantity: 1,
  });

  const fetchLogs = async () => {
    setFetchingLogs(true);
    try {
      const response = await fetch(`${REACT_APP_API_URL}/logs`);
      const data = await response.json();
      setLogs(data.data);
      console.log({ logs: data.data });
    } catch (err) {
      console.log(err);
      message.error(err.message);
    } finally {
      setFetchingLogs(false);
    }
  };

  const stopCalling = async () => {
    console.log('Stopping');
    setStopping(true);
    try {
      const response = await fetch(`${REACT_APP_API_URL}/stop_calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log({ data });
    } catch (err) {
      console.error(err);
      message.error(err.message);
      setStopping(false);
    }
  };

  const startCalling = async () => {
    console.log('Started');
    setCalling(true);
    const logPolling = setInterval(() => {
      fetchLogs();
    }, 5000);
    try {
      const response = await fetch(`${REACT_APP_API_URL}/start_calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          settings,
        }),
      });
      const data = await response.json();
      console.log({ data });
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      clearInterval(logPolling);
      fetchLogs();
      setCalling(false);
      setStopping(false);
    }
  };

  const getFormattedCode = (code, indexOfCode) => JSON.stringify(
    JSON.parse(
      code.slice(indexOfCode, code.length).trim(),
    // .replace(/\s+/g, ' ')
    ),
    null,
    4,
  );

  const showMoreButton = (record) => {
    let indexOfCode = record.subject.indexOf('{');
    // fixes issue with arrays
    if (record.subject.trim()[indexOfCode - 1] === '[') indexOfCode -= 1;

    const containsCode = indexOfCode >= 0;
    let text = record.subject;

    if (containsCode) {
      text = getFormattedCode(record.subject, indexOfCode);
    }
    console.log({ before: record.subject });
    console.log({ text });

    return Modal.info({
      title: record.type,
      content: containsCode ? (
        <div style={{
          overflow: 'auto',
          maxHeight: 500,
        }}
        >
          <code>{text}</code>
        </div>
      ) : text,
      width: 1000,
      onOk() {},
    });
  };

  const logsColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      onCell: (record) => ({
        record,
      }),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text) => (text.length > 180 ? `${text.slice(0, 180)}...` : text),
      onCell: (record) => ({
        record,
      }),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
      onCell: (record) => ({
        record,
      }),
    },
    {
      title: 'Show More',
      dataIndex: 'empty',
      key: 'empty',
      align: 'center',
      render: (_, record) => (
        <Row justify="center">
          <Button
            onClick={() => showMoreButton(record)}
            icon={<EllipsisOutlined />}
          />
        </Row>
      ),
      onCell: (record) => ({
        record,
      }),
    },
  ];

  const onCancelSettings = () => {
    setIsSettingsModalVisible(false);
  };

  const onConfirmSettings = (newSettings) => {
    setIsSettingsModalVisible(false);
    setSettings(newSettings);
  };

  const getRowColor = (logType) => {
    switch (logType) {
      case 'STARTED PROCESS':
        return '#32def14f';
      case 'FINISHED PROCESS':
        return '#23e75085';
      case 'STARTED':
        return '#fbfddd';
      case 'FINISHED':
        return '#ddfde4';
      case 'ERROR':
        return '#f132324f';
      default:
        return 'white';
    }
  };

  const cell = ({ children, record, ...restProps }) => (
    <td
      {...restProps}
      style={{
        padding: 5,
        background: getRowColor(record?.type),
      }}
    >
      {children}
    </td>
  );

  return (
    <div className="App">
      <SettingsModal
        visible={isSettingsModalVisible}
        onCancel={onCancelSettings}
        onConfirm={onConfirmSettings}
      />
      <Row style={{ margin: '50px 0px 20px 0px' }} justify="center">
        <Button
          type="primary"
          size="large"
          onClick={() => setIsSettingsModalVisible(true)}
          disabled={calling}
        >
          Open Settings
        </Button>
      </Row>
      <Row style={{ margin: '30px 0px' }} justify="center" gutter={50}>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={startCalling}
            loading={calling}
          >
            {calling ? 'Calling' : 'Start Calls'}
          </Button>
        </Col>
        <Col>
          <Button
            type="danger"
            size="large"
            onClick={stopCalling}
            loading={stopping}
            disabled={!calling}
          >
            Stop Calls
          </Button>
        </Col>
      </Row>
      <h2>Logs</h2>
      <Table
        style={{ margin: '20px 50px' }}
        columns={logsColumns}
        dataSource={logs}
        rowKey={(record) => record.id}
        loading={fetchingLogs}
        components={{
          body: {
            cell,
          },
        }}
      />
    </div>
  );
}

export default App;
